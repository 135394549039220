<template>
  <genericImporterConfigs v-if="importerConfigs" :configs="importerConfigs"></genericImporterConfigs>
</template>

<script>

import * as R from 'ramda';
import { mapGetters } from 'vuex';
import genericImporterConfigs from './GenericImporterConfigs.vue';

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export default {
  name: 'SFTPGenericImporterEdit',
  components: {
    genericImporterConfigs,
  },
  data() {
    return {
      importerConfigs: null,
    };
  },
  computed: {
    ...mapGetters({
      canAccessSftpFileImporters: 'User/canAccessSftpFileImporters',
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
      canAccessSftpFileTypes: 'User/canAccessSftpFileTypes',
    }),
  },
  async beforeMount() {
    if (!this.canAccessSftpFileImporters || !this.isSftpSettingsEnabled || !this.canAccessSftpFileTypes) {
      this.$router.push('/not-found');
      return;
    }

    await this.loadConfigs();
  },
  methods: {
    async loadConfigs() {
      const res = await this.$store.dispatch('Sftp/GenericImporter/getGenericImporterConfigs', this.$route.params.importerId)
        .then(response => response.data);
      if (!isNullOrEmpty(res)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of res.file_type_schema) {
          item.disabled = {
            field_name: true,
            type: true,
          };
        }
        res.disabled = true;
        this.importerConfigs = res;
      }
    },
  },
};
</script>

<style>

</style>
